// FIXME: PB-6225 - Remove Route.BrightPodcast after the migration
export enum Route {
    Homepage = '',

    // News
    AMP = 'amp/[...slug]',
    Article = 'news/[...slug]',
    CategoryNews = 'category-news/[...slug]',
    Podcast = 'category-news/podcast',
    BrightPodcast = 'category-news/bright-podcast',
    TransferNews = 'news/transfernews',
    TransferRumors = 'transferrumors',
    VideoArticle = 'videos/[slug]/[articleSlug]',
    VideoCategory = 'videos/[slug]',
    VideoOverview = 'videos',

    // Tag Overview
    BrandOverview = 'brands',
    CarOverview = 'cars',
    CircuitOverview = 'circuits',
    ClubOverview = 'clubs',
    CompetitionOverview = 'competitions',
    DossierOverview = 'dossiers',
    DriverOverview = 'f1-drivers',
    F1Calendar = 'f1-calendar',
    GrandsprixOverview = 'grandsprix',
    LocationOverview = 'locations',
    OrganizationOverview = 'organizations',
    PersonOverview = 'people',
    PlayerOverview = 'players',
    RaceClassOverview = 'race-classes',
    TeamBossOverview = 'team-bosses',
    TeamOverview = 'f1-teams',
    TopicOverview = 'topics',

    Premium = 'premium',

    // Tag Detail
    Betting = 'dossiers/wedden',
    Brand = 'brands/[tag]',
    Car = 'cars/[tag]',
    Circuit = 'circuits/[tag]',
    Club = 'clubs/[tag]',
    Competition = 'competitions/[tag]',
    CompetitionAssists = 'competitions/[tag]/assists',
    CompetitionCards = 'competitions/[tag]/cards',
    CompetitionTopscorers = 'competitions/[tag]/topscorers',
    Dossier = 'dossiers/[tag]',
    Driver = 'f1-drivers/[tag]',
    Grandsprix = 'grandsprix/[tag]',
    Location = 'locations/[tag]',
    Organization = 'organizations/[tag]',
    Person = 'people/[tag]',
    Player = 'players/[tag]',
    RaceClass = 'race-classes/[tag]',
    Team = 'f1-teams/[tag]',
    TeamBoss = 'team-bosses/[tag]',
    Topic = 'topics/[tag]',

    // Sport Data
    Match = 'livescores/[date]/[slug]',
    MatchByID = 'livescores/match/[matchID]',
    MatchOverviewDay = 'livescores/[date]',
    MatchOverview = 'livescores',
    Standing = 'standing/[[...slug]]',
    MyFormation = 'my-formation',

    // Profile
    ForgotPassword = 'password-forgot/[[...slug]]',
    Login = 'login',
    Logout = 'logout',
    Notifications = 'profile/notifications',
    Profile = 'profile',
    Register = 'register',
    Settings = 'profile/settings',

    // Supertokens
    VerifyEmail = 'secure/verify-email',
    Signin = 'secure/signin',
    PasswordReset = 'secure/password-reset',
    AttemptRefresh = 'secure/attempt-refresh',

    // Sitemaps
    SitemapIndex = 'sitemap.xml',
    NavigationSitemap = 'sitemap-navigation.xml',
    GoogleNewsSitemap = 'sitemap-google-news.xml',
    SegmentedNewsSitemap = 'sitemap/news/[year]/[week]',
    SegmentedVideosSitemap = 'sitemap/videos/[year]/[month]',
    SegmentedTagSitemap = 'sitemap/[tagType]/[from]/[to]',
    TagSitemap = 'sitemap/[tagType]',

    // RSS
    RssOverview = 'rss-feeds',
    RssWildcard = 'rss',
    RssMedia = 'rss/media.xml',
    Rss = 'rss/index.xml',

    // Others
    AdsTxt = 'ads.txt',
    Browserconfig = 'browserconfig.xml',
    Error = '_error',
    Header = 'header', //FIXME: PB-7011 remove this route after the new header and footer are released.
    Liveness = 'liveness',
    Manifest = 'manifest.json',
    OneSignalSDKWorker = 'OneSignalSDKWorker.js',
    Page = 'page/[slug]',
    Robots = 'robots.txt',
    Sandbox = 'sandbox',
    Search = 'search',
    User = 'user/[slug]',
}
